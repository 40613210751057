import React, { Component, Fragment, type ReactElement } from 'react'
import Footer from '../../common/Footer/Footer'
import HeaderWrapper from '../../common/Header/HeaderWrapper/HeaderWrapper'
import images from '../../common/images'
import BackToTop from '../../common/BackToTop/BackToTop'
import RecruitingStepsPc from './RecruitingStepsPc/RecruitingStepsPc'
import RecruitingStepsMobile from './RecruitingStepsMobile/RecruitingStepsMobile'
import HamburgerMenuHandler from '../../common/Header/HeaderHandler/HamburgerMenuHandler'
import SideBarMenuMobile from '../../common/SideBarMenuMobile/SideBarMenuMobile'
import LoadingState from '../../common/LoadingState/LoadingState'
import './style.scss'
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as AOS from 'aos'
import 'aos/dist/aos.css'

class Careers extends Component {
  private cultureInterval: NodeJS.Timeout | undefined
  componentDidMount(): void {
    AOS.init({
      duration: 1000
    })
    document.title = 'IVA｜AI × ヒトで、世界にもっと輝きを。｜採用情報'
    document.addEventListener('wheel', this.handleScroll)
    document.addEventListener('scroll', this.handleScroll)
    // Set up interval to cycle active class
    this.cultureInterval = setInterval(() => {
      const features = document.querySelectorAll('.careers-culture-feature')
      const images = document.querySelectorAll('.culture-img')
      if (features.length > 0) {
        let activeIndex = -1
        features.forEach((feature, index) => {
          if (feature.classList.contains('actived')) {
            activeIndex = index
            feature.classList.remove('actived')
          }
        })
        const nextIndex = (activeIndex + 1) % features.length
        features[nextIndex].classList.add('actived')

        images.forEach((image, index) => {
          if (index === nextIndex) {
            image.classList.add('active')
          } else {
            image.classList.remove('active')
          }
        })
      }
    }, 5000)
  }

  componentWillUnmount(): void {
    document.removeEventListener('wheel', this.handleScroll)
    document.removeEventListener('scroll', this.handleScroll)
    // Clear the interval
    if (this.cultureInterval) {
      clearInterval(this.cultureInterval)
    }
  }

  render(): ReactElement {
    const qa = {
      selection: [
        {
          title: 'どの職種で応募するか迷っています。複数の職種への応募は可能ですか？',
          content:
            'オープンポジションにてご応募ください！希望や適性を見てご案内させていただきます。'
        },
        {
          title: '応募する前に働き方や条件について詳しく話を聞きたい点があります。',
          content:
            'エントリー後のメッセージにてその旨をお知らせください。選考前にカジュアル面談を実施させていただければと思います。'
        },
        {
          title: 'オンラインでの面接は可能ですか？',
          content:
            '基本的に一次面接はオンライン、最終面接は対面での実施をお願いしています。一次面接も対面をご希望の方は対応可能ですのでお知らせください。'
        },
        {
          title: '過去にIVAの選考を受けたのですが、再びエントリーすることは可能ですか？',
          content:
            'もちろん可能です。募集ポジションや職務内容は随時更新しているため、ご確認の上、ご応募ください。'
        }
      ],
      business: [
        {
          title: 'どのように社内でコミュニケーションをとっていますか？',
          content:
            'Slack、ZoomなどのコミュニケーションツールやNotionなどのドキュメントサービスを使い、円滑なコミュニケーションや情報の透明性が保たれるようにしています。対面でのコミュニケーションも積極的に実施していて、社員同士のランチなどの活動もサポートしています。'
        },
        {
          title: 'リモートで働くことは可能ですか？',
          content: 'リモートワークは現在検討中で、原則出社をお願いしています。'
        }
      ]
    }

    const pickup = [
      {
        date: '24/4/8',
        title: '【代表インタビュー】ガムシャラに、粛々と。CEO相原嘉夫が語るIVAの軌跡と展望',
        content:
          '今回は創業者CEOの相原嘉夫に生い立ちやIVAの創業秘話、事業にかける想いをインタビューしました。',
        img: images.careersCeo,
        link: 'https://www.wantedly.com/companies/company_6154268/post_articles/888581'
      },
      {
        date: '24/4/10',
        title: '【インタビュー】シニア UXデザイナー：デザインを通して組織の共創を加速する',
        content:
          '今回はシニアデザイナーの塩澤源太にIVA入社の契機や今後の展望をインタビューしました。',
        img: images.careersDesigner,
        link: 'https://www.wantedly.com/companies/company_6154268/post_articles/889132'
      },
      {
        date: '24/11/11',
        title:
          '【インタビュー】コーポレート部統括マネージャ：手を挙げることで拓く、自身と組織の成長',
        content:
          '今回はコーポレート部統括マネージャの玉野菫にIVA入社の契機や今後の展望をインタビューしました。',
        img: images.careersDesigner2,
        link: 'https://www.wantedly.com/companies/company_6154268/post_articles/918917'
      }
    ]

    return (
      <Fragment>
        <div className='careers main-content'>
          <LoadingState />
          <HeaderWrapper shouldShowBlackLogo={true} />
          <BackToTop />
          <div id='careersPc'>
            <div id='careersTitle'>
              <img src={images.careersTitle} alt='title' />
            </div>
            <div className='careers-container' style={{ backgroundColor: 'rgb(247, 247, 247)' }}>
              <div className='careers-container-title' style={{ marginTop: '0px' }}>
                <span>Our team</span>
                <span className='careers-container-title-sub-title'>私たちのチーム</span>
              </div>
              <img src={images.careersOurTeam} alt='careersOurTeam' />
              <div className='careers-container-title' data-aos='goin'>
                <span>Welfare benefits</span>
                <span className='careers-container-title-sub-title'>福利厚生</span>
              </div>
              <img
                src={images.careersWelfareBenefits}
                alt='careersWelfareBenefits'
                data-aos='goin'
              />
            </div>
            <div className='careers-container' data-aos='goin'>
              <div className='careers-container-title'>
                <span>Communication</span>
                <span className='careers-container-title-sub-title'>コミュニケーション</span>
              </div>
              <img src={images.careersCommunication} alt='careersCommunication' />
              <div className='careers-container-title'>
                <span>Pick up</span>
                <span className='careers-container-title-sub-title'>ピックアップ</span>
              </div>
              <div id='pickUpPc'>
                {pickup.map((item, index) => (
                  <a
                    className='pick-up-card'
                    key={index}
                    href={item.link}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <div className='pick-up-content'>
                      <div className='pick-up-tag'>
                        <p>{item.date}</p>
                        <img src={images.wantedlyLogo} alt='wantedlyLogo' />
                      </div>
                      <p className='pick-up-content-title'>{item.title}</p>
                      <p className='pick-up-content-text'>{item.content}</p>
                    </div>
                    <div className='pick-up-img'>
                      <img className='pick-up-main-img' src={item.img} alt='pickUpMainImage' />
                    </div>
                  </a>
                ))}
              </div>
            </div>
            <div className='careers-container' data-aos='goin'>
              <div className='careers-container-title'>
                <span>Gallery</span>
                <span className='careers-container-title-sub-title'>ギャラリー</span>
              </div>
              <div id='gallery-area'>
                <div className='gallery-items'>
                  <img src={images.careersGallery1} alt='careersGallery1' />
                  <img src={images.careersGallery2} alt='careersGallery2' />
                  <img src={images.careersGallery3} alt='careersGallery3' />
                </div>
                <div className='gallery-items'>
                  <img src={images.careersGallery4} alt='careersGallery4' />
                  <img src={images.careersGallery5} alt='careersGallery5' />
                  <img src={images.careersGallery6} alt='careersGallery6' />
                </div>
                <div className='gallery-items'>
                  <img src={images.careersGallery7} alt='careersGallery7' />
                  <img src={images.careersGallery8} alt='careersGallery8' />
                  <img src={images.careersGallery9} alt='careersGallery9' />
                </div>
              </div>
              {/* <img src={images.careersGallery} alt='careersGallery' /> */}
            </div>
            <div className='careers-container' data-aos='goin'>
              <div className='careers-container-title'>
                <span>Recruiting philosorhy</span>
                <span className='careers-container-title-sub-title'>採用フロー</span>
              </div>
              <img src={images.careersRecruitingPhilosorhyText} alt='careersRecruitingPhilosorhy' />
              <RecruitingStepsPc />
              <p style={{ placeSelf: 'center' }}>
                入社後のミスマッチを双方で防ぐため、必要に応じて採用フローを変更する場合があります。
              </p>
            </div>
            <div
              className='careers-contactUs'
              data-aos='goin'
              style={{ marginTop: 0, marginBottom: '50px' }}
            >
              <a href='https://herp.careers/v1/iva0403' target='_blank' rel='noreferrer'>
                <button
                  className='nav-button button'
                  style={{ height: '70px', width: '285px' }}
                  type='button'
                >
                  <div className='nav-buttonText'>
                    職種を見る
                    <div className='arrow right'></div>
                  </div>
                </button>
              </a>
              <a
                href='https://www.wantedly.com/companies/company_6154268'
                target='_blank'
                rel='noreferrer'
              >
                <button
                  className='nav-button button'
                  style={{ height: '70px', width: '285px' }}
                  type='button'
                >
                  <div className='nav-buttonText'>
                    Wantedly 採用ページ
                    <div className='arrow right'></div>
                  </div>
                </button>
              </a>
            </div>
            <div className='careers-container' style={{ marginBottom: '80px' }} data-aos='goin'>
              <div className='careers-container-title'>
                <span>Q&A</span>
                <span className='careers-container-title-sub-title'>よくある質問</span>
              </div>
              <div className='accordion-block'>
                <span className='accordion-block-title'>応募選考プロセス</span>
                {qa.selection.map((item, index) => (
                  <div className='accordion-item' key={index}>
                    <div
                      className='accordion-item-title'
                      onClick={(event) =>
                        this.expandDescription(event.currentTarget, 'selection', index)
                      }
                    >
                      <p className='accordion-item-title-text'>{item.title}</p>
                      <img
                        className='selection-accordion-icon-down'
                        src={images.greyArrow}
                        alt='accordionDown'
                      />
                    </div>
                    <div className='selection-accordion-content accordion-item-content'>
                      <div className='accordion-item-content-text'>
                        <p>{item.content}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className='accordion-block'>
                <span className='accordion-block-title'>業務内容・カルチャー</span>
                {qa.business.map((item, index) => (
                  <div className='accordion-item' key={index}>
                    <div
                      className='accordion-item-title'
                      onClick={(event) =>
                        this.expandDescription(event.currentTarget, 'business', index)
                      }
                    >
                      <p className='accordion-item-title-text'>{item.title}</p>
                      <img
                        className='business-accordion-icon-down'
                        src={images.greyArrow}
                        alt='accordionDown'
                      />
                    </div>
                    <div className='business-accordion-content accordion-item-content'>
                      <div className='accordion-item-content-text'>
                        <p>{item.content}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className='careers-contactUs' data-aos='goin'>
              <a href='https://herp.careers/v1/iva0403' target='_blank' rel='noreferrer'>
                <button
                  className='nav-button button'
                  style={{ height: '70px', width: '285px' }}
                  type='button'
                >
                  <div className='nav-buttonText'>
                    職種を見る
                    <div className='arrow right'></div>
                  </div>
                </button>
              </a>
              <a
                href='https://www.wantedly.com/companies/company_6154268'
                target='_blank'
                rel='noreferrer'
              >
                <button
                  className='nav-button button'
                  style={{ height: '70px', width: '285px' }}
                  type='button'
                >
                  <div className='nav-buttonText'>
                    Wantedly 採用ページ
                    <div className='arrow right'></div>
                  </div>
                </button>
              </a>
            </div>
          </div>
          <div id='careersMobile'>
            <div id='careersTitle'>
              <img src={images.careersTitleMobile} alt='title' />
            </div>
            <div
              className='careers-container'
              style={{ gap: '20px', marginTop: '50px' }}
              data-aos='goin'
            >
              <div className='careers-container-title'>
                <span>Our team</span>
                <span className='careers-container-title-sub-title'>私たちのチーム</span>
              </div>
              <img src={images.careersOurTeamMobile1} alt='careersOurTeam1' />
              <img src={images.careersOurTeamMobile2} alt='careersOurTeam2' />
              <img src={images.careersOurTeamMobile3} alt='careersOurTeam3' />
              <img src={images.careersOurTeamMobile4} alt='careersOurTeam4' />
              <img src={images.careersOurTeamMobile5} alt='careersOurTeam5' />
              <img src={images.careersOurTeamMobile6} alt='careersOurTeam6' />
            </div>
            <div className='careers-container' style={{ gap: '27px' }} data-aos='goin'>
              <div className='careers-container-title'>
                <span>Welfare benefits</span>
                <span className='careers-container-title-sub-title'>福利厚生</span>
              </div>
              <img src={images.careersWelfareBenefitsMobile1} alt='careersWelfareBenefitsMobile1' />
              <img src={images.careersWelfareBenefitsMobile2} alt='careersWelfareBenefitsMobile2' />
              <img src={images.careersWelfareBenefitsMobile3} alt='careersWelfareBenefitsMobile3' />
              <img src={images.careersWelfareBenefitsMobile4} alt='careersWelfareBenefitsMobile4' />
              <img src={images.careersWelfareBenefitsMobile5} alt='careersWelfareBenefitsMobile5' />
              <img src={images.careersWelfareBenefitsMobile6} alt='careersWelfareBenefitsMobile6' />
              <img src={images.careersWelfareBenefitsMobile7} alt='careersWelfareBenefitsMobile7' />
              <img src={images.careersWelfareBenefitsMobile8} alt='careersWelfareBenefitsMobile8' />
            </div>
            <div className='careers-container' style={{ gap: '28px' }} data-aos='goin'>
              <div className='careers-container-title'>
                <span>Communication</span>
                <span className='careers-container-title-sub-title'>コミュニケーション</span>
              </div>
              <img src={images.careersCommunicationMobile} alt='careersCommunicationMobile' />
            </div>
            <div className='careers-container' style={{ gap: '28px' }} data-aos='goin'>
              <div className='careers-container-title'>
                <span>Pick up</span>
                <span className='careers-container-title-sub-title'>ピックアップ</span>
              </div>
              <div id='pickUpMobile'>
                {pickup.map((item, index) => (
                  <a
                    className='pick-up-content'
                    key={index}
                    href={item.link}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <img className='pick-up-main-img' src={item.img} alt='pickUpMainImage' />
                    <div className='pick-up-tag'>
                      <p>{item.date}</p>
                      <img src={images.wantedlyLogo} alt='wantedlyLogo' />
                    </div>
                    <p className='pick-up-title'>{item.title}</p>
                  </a>
                ))}
              </div>
            </div>
            <div className='careers-container' style={{ gap: '28px' }} data-aos='goin'>
              <div className='careers-container-title'>
                <span>Gallery</span>
                <span className='careers-container-title-sub-title'>ギャラリー</span>
              </div>
              <img src={images.careersGalleryMobile} alt='careersGalleryMobile' />
            </div>
            <div className='careers-container' style={{ gap: '28px' }} data-aos='goin'>
              <div className='careers-container-title'>
                <span>Recruiting philosorhy</span>
                <span className='careers-container-title-sub-title'>採用フロー</span>
              </div>
              <img
                src={images.careersRecruitingPhilosorhyMobile1}
                alt='careersRecruitingPhilosorhyMobile1'
              />
              <p style={{ marginBottom: 0, marginTop: '20px', fontSize: '20px' }}>
                <b>採用フロー</b>
              </p>
              <RecruitingStepsMobile />
              <p>
                入社後のミスマッチを双方で防ぐため、必要に応じて採用フローを変更する場合があります。
              </p>
              <div className='careers-contactUs'>
                <a href='https://herp.careers/v1/iva0403' target='_blank' rel='noreferrer'>
                  <button
                    className='nav-button button'
                    style={{ height: '70px', width: '285px' }}
                    type='button'
                  >
                    <div className='nav-buttonText'>
                      職種を見る
                      <div className='arrow right'></div>
                    </div>
                  </button>
                </a>
                <a
                  href='https://www.wantedly.com/companies/company_6154268'
                  target='_blank'
                  rel='noreferrer'
                >
                  <button
                    className='nav-button button'
                    style={{ height: '70px', width: '285px' }}
                    type='button'
                  >
                    <div className='nav-buttonText'>
                      Wantedly 採用ページ
                      <div className='arrow right'></div>
                    </div>
                  </button>
                </a>
              </div>
            </div>
            <div className='careers-container' style={{ marginBottom: '80px' }} data-aos='goin'>
              <div className='careers-container-title'>
                <span>Q&A</span>
                <span className='careers-container-title-sub-title'>よくある質問</span>
              </div>
              <div className='accordion-block'>
                <span className='accordion-block-title'>応募選考プロセス</span>
                {qa.selection.map((item, index) => (
                  <div
                    className='accordion-item'
                    key={index}
                    onClick={(event) =>
                      this.expandDescription(event.currentTarget, 'selection-mobile', index)
                    }
                  >
                    <div className='accordion-item-title'>
                      <p className='accordion-item-title-text'>{item.title}</p>
                      <img
                        className='selection-mobile-accordion-icon-down'
                        src={images.greyArrow}
                        alt='accordionDown'
                      />
                    </div>
                    <div className='selection-mobile-accordion-content accordion-item-content'>
                      <div className='accordion-item-content-text'>
                        <p>{item.content}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className='accordion-block'>
                <span className='accordion-block-title'>業務内容・カルチャー</span>
                {qa.business.map((item, index) => (
                  <div
                    className='accordion-item'
                    key={index}
                    onClick={(event) =>
                      this.expandDescription(event.currentTarget, 'business-mobile', index)
                    }
                  >
                    <div className='accordion-item-title'>
                      <p className='accordion-item-title-text'>{item.title}</p>
                      <img
                        className='business-mobile-accordion-icon-down'
                        src={images.greyArrow}
                        alt='accordionDown'
                      />
                    </div>
                    <div className='business-mobile-accordion-content accordion-item-content'>
                      <div className='accordion-item-content-text'>
                        <p>{item.content}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className='careers-contactUs' data-aos='goin'>
              <a href='https://herp.careers/v1/iva0403' target='_blank' rel='noreferrer'>
                <button
                  className='nav-button button'
                  style={{ height: '70px', width: '285px' }}
                  type='button'
                >
                  <div className='nav-buttonText'>
                    職種を見る
                    <div className='arrow right'></div>
                  </div>
                </button>
              </a>
              <a
                href='https://www.wantedly.com/companies/company_6154268'
                target='_blank'
                rel='noreferrer'
              >
                <button
                  className='nav-button button'
                  style={{ height: '70px', width: '285px' }}
                  type='button'
                >
                  <div className='nav-buttonText'>
                    Wantedly 採用ページ
                    <div className='arrow right'></div>
                  </div>
                </button>
              </a>
            </div>
          </div>
          <Footer />
        </div>
        <SideBarMenuMobile handler={new HamburgerMenuHandler(true)} />
      </Fragment>
    )
  }

  private readonly handleScroll = (): void => {
    this.handleAnimationElements()
    this.handleSectionAnimation()
  }

  private readonly handleAnimationElements = (): void => {
    const animationElements = Array.from(
      document.querySelectorAll('.title-animation')
    ) as HTMLElement[]
    const reverseAnimationElement = Array.from(
      document.querySelectorAll('.title-reverse-animation')
    ) as HTMLElement[]
    const windowHeight = window.innerHeight

    const elements = [...animationElements, ...reverseAnimationElement]
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i]
      if (element) {
        const elementTop = element.getBoundingClientRect().top
        const elementVisible = 10

        if (elementTop < windowHeight - elementVisible) {
          element.classList.add('active')
        } else {
          element.classList.remove('active')
        }
      }
    }
  }

  private readonly handleSectionAnimation = (): void => {
    const animationElements = document.querySelectorAll('.section-animation')
    const windowHeight = window.innerHeight

    for (let i = 0; i < animationElements.length; i++) {
      const elementTop = animationElements[i].getBoundingClientRect().top
      const elementVisible = 10

      if (elementTop < windowHeight - elementVisible) {
        animationElements[i].classList.add('active')
      } else {
        animationElements[i].classList.remove('active')
      }
    }
  }

  private readonly expandDescription = (
    element: HTMLDivElement,
    accordionName: string,
    accordionIndex: number
  ): void => {
    const accordionIconDowns = document.querySelectorAll(
      '.' + accordionName + '-accordion-icon-down'
    )
    const accordionContents = document.querySelectorAll('.' + accordionName + '-accordion-content')
    // accordion icon
    accordionIconDowns.forEach((accordionIconDown, index) => {
      const accordionIconDownEl = accordionIconDown as HTMLDivElement
      if (index === accordionIndex) {
        const accordionIconDownRotate = accordionIconDownEl
          ? accordionIconDownEl.style.transform
          : ''
        if (accordionIconDownRotate === '') accordionIconDownEl.style.transform = 'rotate(180deg)'
        else accordionIconDownEl.style.transform = ''
      } else accordionIconDownEl.style.transform = ''
    })
    // accordion content
    accordionContents.forEach((accordionContent, index) => {
      const accordionContentEl = accordionContent as HTMLDivElement
      // 判斷是不是自己被點選
      if (index === accordionIndex) {
        const accordionContentDrop = accordionContentEl.style.height !== ''
        // 判斷是否是已被點選的狀態
        if (accordionContentDrop) {
          accordionContentEl.style.height = ''
          accordionContentEl.style.opacity = ''
        } else {
          const accordionHeight = accordionContentEl.scrollHeight
          accordionContentEl.style.height = String(accordionHeight) + 'px'
          accordionContentEl.style.opacity = '100'
        }
      } else {
        accordionContentEl.style.height = ''
        accordionContentEl.style.opacity = ''
      }
    })
  }
}

export default Careers
